import React, { useState } from 'react';
import { Form, InputPicker, MaskedInput, Schema, Uploader } from 'rsuite';
import { DecryptionUploaderWrapper, GridButtons, SuccessMessage, UploaderWrapper } from '../../styles/ModalStyles';
import { useTranslation } from 'react-i18next';
import AreaCodes from '../../model/AreaCodes';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import Button from '../../components/Button';
import { FileType } from 'rsuite/Uploader';
import { showErrorNotification, showSuccessNotification } from '../../services/notificationService';
import {
    decryptPublicFile,
    downloadPublicFile,
    sendPublicDecryptCode,
    validatePublicFile
} from '../../services/decryptServices';
import {
    Container,
    FirstStepWrapper,
    Grid,
    InputWrapper,
    SecondStepWrapper
} from '../../styles/FileDecryptionPageStyle';
import { downloadFile } from '../../services/downloadFile';
import { useNavigate } from 'react-router-dom';
import { KryptliLink } from '../../styles/LoginPanelWrapperStyles';
import ModalSmsMessages from '../../components/ModalSmsMessages';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface Form {
    areaCode: string;
    phoneNumber: string;
}

interface IView {
    uploadFile: boolean;
    verificationCode: boolean;
    successMessage: boolean;
}

export interface ISmsMessageModal {
    openModal: boolean;
    smsMessage: string;
    password: string;
}

const FileDecryptionPage = () => {
    const { t } = useTranslation();
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        phoneNumber: StringType()
            .isRequired(t('COMMON.IS_REQUIRED') as string)
            .addRule(
                (value) => isValidPhoneNumber(formValue.areaCode + value),
                t('MODAL_NEW_CONTACT.IS_VALID_AREA_CODE') as string
            )
    });

    const [fileUrl, setFileUrl] = useState<string>('');
    const [view, setView] = useState<IView>({
        uploadFile: true,
        verificationCode: false,
        successMessage: false
    });

    const navigate = useNavigate();

    const formRef = React.useRef<any>();
    const [value, setValue] = useState<any>([]);
    const initialState: Form = { areaCode: '+48', phoneNumber: '' };
    const [formValue, setFormValue] = React.useState<any>(initialState);
    const [formError, setFormError] = React.useState<any>({});
    const [checkTrigger, setCheckTrigger] = React.useState<TypeAttributes.CheckTrigger>('change');
    const maxFileSize = 1024 * 1024 * 25;

    const [phoneNumberFormatter, setPhoneNumberFormatter] = React.useState<string>('');
    const [codeValue, setCodeValue] = React.useState<string>('');
    const [fileId, setFileId] = React.useState<string>('');

    const isSmsMessages = useSelector((state: RootState) => state.featureToggle.smsMessages);

    const [smsMessageModal, setSmsMessageModal] = useState<ISmsMessageModal>({
        openModal: false,
        smsMessage: '',
        password: ''
    });

    const isUploaderValid = () => {
        return value.length === 1 && isValidPhoneNumber(formValue.areaCode + formValue.phoneNumber);
    };

    const isCodeValid = () => {
        const code = codeValue.replaceAll('_', '');
        return code.length === 6;
    };

    const handleNextUpload = () => {
        setFileUrl('');
        setValue([]);
        setFormValue(initialState);
        setPhoneNumberFormatter('');
        setView({
            uploadFile: true,
            verificationCode: false,
            successMessage: false
        });
        setCodeValue('');
    };

    const handleBackHomePage = () => {
        navigate('/');
    };

    const handleUpload = () => {
        return decryptPublicFile(value[0].blobFile.name, value[0].blobFile, formValue.areaCode + formValue.phoneNumber)
            .then((fileId) => {
                setFileId(fileId);
                setPhoneNumberFormatter(
                    parsePhoneNumber(formValue.areaCode + formValue.phoneNumber).formatInternational()
                );
                return validatePublicFile(fileId);
            })
            .then((response: any) => {
                setView({ ...view, uploadFile: false, verificationCode: true });
                if (!isSmsMessages) {
                    setSmsMessageModal({
                        openModal: true,
                        smsMessage: t('MODAL_SMS_MESSAGE.GENERATE_PIN_CODE_MESSAGE'),
                        password: response.verificationCode
                    });
                }
            })
            .catch((err) => {
                if (err === 'UNKNOWN_FILE') {
                    showErrorNotification(t('NOTIFICATION.FILE_COULD_NOT_DECRYPTED') as string);
                } else if (err === 'VALIDATION_ERROR') {
                    showErrorNotification(t('NOTIFICATION.INVALID_PHONE_NUMBER') as string);
                } else {
                    showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
                }
            });
    };

    const handleUploadCode = () => {
        downloadPublicFile(fileId, codeValue)
            .then((url: any) => {
                downloadFile(url);
                setFileUrl(url);
                setView({ ...view, uploadFile: false, verificationCode: false, successMessage: true });
            })
            .catch((err) => {
                if (err.message === 'INVALID_CODE') {
                    showErrorNotification(t('FILE_DECRYPTION_PAGE.INVALID_CODE') as string);
                } else {
                    showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
                }
            });
    };

    const handleSendCode = () => {
        sendPublicDecryptCode(fileId)
            .then((r) => {
                showSuccessNotification(t('FILE_DECRYPTION_PAGE.CODE_SENT_BY_SMS') as string);
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.INVALID_HANDLE_SEND_CODE') as string);
            });
    };

    const handleUploadFileChange = (fileList: FileType[]) => {
        if (fileList.length > 0) {
            const lastFile = fileList[fileList.length - 1];
            if (lastFile.blobFile && lastFile.blobFile.size > maxFileSize) {
                showErrorNotification(t('NOTIFICATION.THE_SELECTED_FILE_IS_TOO_LARGE') as string);
                setValue([]);
            } else {
                setValue([fileList[fileList.length - 1]]);
            }
        } else {
            setValue(fileList);
        }
    };

    return (
        <Container>
            <img src="/assets/img/logo.svg" alt={t('ALT.KRYPTLI_APP_LOGO') as string} style={{ height: 70 }} />
            <Grid>
                <img src="/assets/img/iconSafe.png" alt={t('ALT.KRYPTLI_APP_LOGO') as string} />
                <h3>{t('FILE_DECRYPTION_PAGE.DECRYPT_YOUR_FILE')}</h3>
                <p>{t('FILE_DECRYPTION_PAGE.STEPS_DECRYPT_FILE')}</p>
                {view.uploadFile && (
                    <FirstStepWrapper>
                        <DecryptionUploaderWrapper>
                            <Uploader
                                draggable
                                fileList={value}
                                autoUpload={false}
                                onChange={handleUploadFileChange}
                                multiple={false}
                                /* Action jest wymagany, dlatego został ustawiony pusty string */
                                action={''}
                            >
                                <UploaderWrapper>
                                    <p>
                                        <a>{t('MODAL_FILE_DECRYPTION.CHOOSE_FILE')}</a>
                                        {t('MODAL_FILE_DECRYPTION.OR_DRAG_HERE')}
                                    </p>
                                </UploaderWrapper>
                            </Uploader>
                        </DecryptionUploaderWrapper>
                        <p>{t('FILE_DECRYPTION_PAGE.PHONE_NUMBER')}</p>
                        <Form
                            ref={formRef}
                            onChange={setFormValue}
                            formValue={formValue}
                            onCheck={setFormError}
                            formError={formError}
                            model={model}
                            checkTrigger={checkTrigger}
                        >
                            <Form.Group>
                                <Form.Control
                                    name="areaCode"
                                    accepter={InputPicker}
                                    data={AreaCodes}
                                    defaultValue={'+48'}
                                    cleanable={false}
                                    style={{ width: 80, marginRight: 5 }}
                                    renderValue={() => formValue.areaCode}
                                />
                                <Form.Control
                                    name="phoneNumber"
                                    errorMessage={formError.phoneNumber}
                                    style={{ width: 300 }}
                                />
                            </Form.Group>
                        </Form>
                        <GridButtons>
                            <Button
                                appearance="primary"
                                canClick={isUploaderValid}
                                onClick={handleUpload}
                                text={t('FILE_DECRYPTION_PAGE.GENERATE_PIN_CODE')}
                                disabled={!isUploaderValid()}
                            />
                            <Button
                                appearance={'link'}
                                onClick={handleBackHomePage}
                                text={t('FILE_DECRYPTION_PAGE.GO_BACK_HOME_PAGE')}
                            />
                        </GridButtons>
                    </FirstStepWrapper>
                )}
                {view.verificationCode && (
                    <SecondStepWrapper>
                        <p>{t('FILE_DECRYPTION_PAGE.ENTER_CODE')}</p>
                        <p>
                            <strong>{phoneNumberFormatter}</strong>
                        </p>
                        <InputWrapper>
                            <p>{t('FILE_DECRYPTION_PAGE.VERIFICATION_CODE')}</p>
                            <MaskedInput
                                value={codeValue}
                                onChange={setCodeValue}
                                showMask={false}
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                placeholder={'_ _ _ _ _ _'}
                                guide={false}
                                keepCharPositions={true}
                            />
                        </InputWrapper>
                        <GridButtons>
                            <Button
                                appearance="primary"
                                onClick={handleUploadCode}
                                text={t('FILE_DECRYPTION_PAGE.VERIFY')}
                                disabled={!isCodeValid()}
                            />
                            <Button
                                appearance={'link'}
                                onClick={handleSendCode}
                                text={t('FILE_DECRYPTION_PAGE.RESEND_CODE')}
                            />
                            <Button
                                appearance={'link'}
                                onClick={handleBackHomePage}
                                text={t('FILE_DECRYPTION_PAGE.GO_BACK_HOME_PAGE')}
                            />
                        </GridButtons>
                    </SecondStepWrapper>
                )}
                {view.successMessage && (
                    <SecondStepWrapper>
                        <SuccessMessage>
                            <img src="/assets/img/iconSuccess.svg" alt={t('ALT.SUCCESS_ICON') as string} />
                            <div>
                                <h5>{t('MODAL_FILE_DECRYPTION.SUCCESS_MESSAGE_HEADER')}</h5>
                                <p>
                                    {t('MODAL_FILE_DECRYPTION.SUCCESS_MESSAGE_TEXT')}
                                    <a href={fileUrl}> {t('MODAL_FILE_DECRYPTION.SUCCESS_MESSAGE_LINK')}</a>.
                                </p>
                            </div>
                        </SuccessMessage>
                        <GridButtons>
                            <Button
                                onClick={handleNextUpload}
                                text={t('MODAL_FILE_DECRYPTION.DECRYPT_NEXT_FILE')}
                                appearance="primary"
                            />
                            <Button
                                appearance={'link'}
                                onClick={handleBackHomePage}
                                text={t('FILE_DECRYPTION_PAGE.GO_BACK_HOME_PAGE')}
                            />
                        </GridButtons>
                    </SecondStepWrapper>
                )}
            </Grid>
            <p>
                {t('FILE_DECRYPTION_PAGE.KRYPTLI_PAGE_TEXT')}
                <KryptliLink href="https://kryptli.com/#how-it-works" target="_blank">
                    {t('FILE_DECRYPTION_PAGE.KRYPTLI_PAGE_LINK')}
                </KryptliLink>
                .
            </p>
            <ModalSmsMessages smsMessageModal={smsMessageModal} setSmsMessageModal={setSmsMessageModal} />
        </Container>
    );
};

export default FileDecryptionPage;
