export default {
    common: {
        loading: 'Ładowanie...',
        emptyMessage: 'Brak danych'
    },
    Plaintext: {
        unfilled: 'Niewypełnione',
        notSelected: 'Nie wybrano',
        notUploaded: 'Nie przesłano'
    },
    Pagination: {
        more: 'Więcej',
        prev: 'Poprzednia',
        next: 'Następna',
        first: 'Pierwsza',
        last: 'Ostatnia',
        limit: '{0}',
        total: 'Wszystkich wpisów: {0}',
        skip: 'Idź do{0}'
    },
    Uploader: {
        inited: 'Początkowe',
        progress: 'Przesyłanie',
        error: 'Błąd',
        complete: 'Gotowe',
        emptyFile: 'Brak pliku',
        upload: 'Wybierz plik'
    },
    CloseButton: {
        closeLabel: 'Zamknij'
    },
    Breadcrumb: {
        expandText: 'Show path'
    },
    Toggle: {
        on: 'Open',
        off: 'Close'
    },
    COMMON: {
        CANCEL: 'Anuluj',
        NUMBER_ENTRIES_ON_PAGE: 'Ilość wpisów na stronie',
        USER: 'Użytkownik',
        ACTION: 'Czynność',
        ADDITIONAL_INFORMATION: 'Dodatkowe informacje',
        IS_REQUIRED: 'To pole jest wymagane.',
        IS_EMAIL: 'Podaj poprawny adres email.',
        GO_BACK_LOGIN_PAGE: 'Wróć do strony logowania'
    },
    ACTIONS: {
        EDIT_DATA: 'Edytuj dane',
        PASSWORD_SETTINGS: 'Ustawienia hasła',
        ACTIVITY_HISTORY: 'Historia czynności',
        DEACTIVATE_CONTACT: 'Dezaktywuj Kontakt',
        ENCRYPT_FILE: 'Zaszyfruj plik',
        UPLOAD_FILE: 'Załaduj plik do zaszyfrowania',
        ENCRYPT_NEXT_FILE: 'Zaszyfruj następny plik',
        SEARCH: 'Szukaj...',
        SHOW_DEACTIVATED_CONTACTS: 'Pokaż dezaktywowane kontakty',
        ADD_NEW_CONTACT: 'Dodaj nowy kontakt',
        ADD_FIRST_CONTACT: 'Dodaj swój pierwszy kontakt',
        GO_BACK_CONTACT_LIST: 'Wróć do listy kontaktów',
        SELECT_RECIPIENTS: 'Odbiorcy',
        SELECT_RECIPIENTS_INSTRUCTION:
            'Wybierz kontakty z listy lub wpisz email, aby zaszyfrować plik dla wybranych odbiorców:'
    },
    NAVBAR_ITEMS: {
        DECRYPT_FILE: 'Odszyfruj plik',
        TUTORIAL: 'Jak to działa?',
        LOG_OUT: 'Wyloguj'
    },
    CONTACT: {
        CONTACT_DETAILS: 'Dane kontaktu',
        EMAIL: 'E-mail',
        PHONE: 'Telefon',
        DESCRIPTION: 'Opis',
        CONTACT_LIST: 'Lista kontaktów',
        EMPTY_CONTACT_LIST: 'Lista kontaktów jest pusta'
    },
    NOTIFICATION: {
        ERROR_NOTIFICATION: 'Wystąpił błąd komunikacji z serwerem. Skontaktuj się z administratorem strony.',
        CONTACT_DEACTIVATED: 'Dezaktywowano kontakt.',
        CONTACT_DETAILS_CHANGED: 'Dane kontaktu zostały zmienione.',
        THE_SELECTED_FILE_IS_TOO_LARGE: 'Wybrany plik jest za duży. Maksymalny rozmiar to 25 MB.',
        FILE_COULD_NOT_DECRYPTED: 'Nie można odszyfrować pliku. Wybrany plik nie został zaszyfrowany.',
        FAILED_SEND_PASSWORD: 'Nie udało się wysłać hasła do kontaktu. Skontaktuj się z administratorem strony.',
        FAILED_CREATE_CLIENT:
            'Nie udało się utworzyć kontaktu. Spróbuj ponownie później lub skontaktuj się z administratorem strony.',
        GENERATED_AND_SENT_PASSWORD: 'Nowe hasło zostało wygenerowane i wysłane.',
        PASSWORD_GENERATION_FAILED: 'Nie udało się wygenerować hasła. Skontaktuj się z administratorem strony.',
        CURRENT_PASSWORD_SENT: 'Aktualne hasło zostało wysłane.',
        COULD_NOT_SEND_PASSWORD: 'Nie udało się wysłać aktualnego hasła. Skontaktuj się z administratorem strony.',
        NEW_CLIENT_ADDED: 'Nowy kontakt został dodany.',
        ATTEMPT_LIMIT_EXCEEDED:
            'Przekroczony limit ponownego wysyłania wiadomości. Spróbuj ponownie później lub skontaktuj się z administratorem strony.',
        USER_NOT_CONFIRMED:
            'Podany użytkownik nie został jeszcze potwierdzony. Sprawdź swoją skrzynkę pocztową i potwierdź rejestrację.',
        USER_NOT_FOUND: 'Podany użytkownik nie istnieje. Sprawdź wprowadzone dane i spróbuj ponownie.',
        INVALID_PHONE_NUMBER: 'Podany numer telefonu jest niepoprawny. Sprawdź numer i spróbować ponownie.',
        INVALID_CODE: 'Kod weryfikacyjny jest niepoprawny.',
        INVALID_HANDLE_SEND_CODE:
            'Nie można wysłać ponownie kodu. Spróbuj później lub skontaktuj się z administratorem strony.',
        SEND_CODE: 'Kod weryfikacyjny został ponownie wysłany.'
    },
    HISTORY_PAGE: {
        HISTORY: 'Historia',
        SEARCH: 'Wyszukiwanie'
    },
    FILE_DECRYPTION_PAGE: {
        DECRYPT_YOUR_FILE: 'Odszyfruj swój plik',
        STEPS_DECRYPT_FILE:
            'Wrzuć swój zaszyfrowany plik poniżej. Podaj numer telefonu, na który wyślemy Ci kod weryfikacyjny. Po wpisaniu numeru odszyfrowany plik pobierze się automatycznie.',
        GENERATE_PIN_CODE: 'Wyślij kod weryfikacyjny',
        ENTER_THE_PIN_CODE_AND_DECRYPT_FILE: 'Podaj kod pin i odszyfruj plik',
        CODE_SENT_BY_SMS: 'Twój kod weryfikacyjny został przesłany drogą SMS',
        DECRYPT_AND_DOWNLOAD_FILE: 'Odszyfruj i pobierz plik',
        PHONE_NUMBER: 'Numer telefonu',
        ENTER_CODE: 'Wpisz 6-cyfrowy kod wysłany na numer telefonu:',
        VERIFICATION_CODE: 'Kod weryfikacyjny',
        INVALID_CODE: 'Podany kod weryfikacyjny jest błędny. Sprawdź kod i spróbuj ponownie.',
        VERIFY: 'Zweryfikuj',
        RESEND_CODE: 'Wyślij ponownie kod',
        GO_BACK_HOME_PAGE: 'Wróć na stronę główną',
        DECRYPT_NEXT_FILE: 'Odszyfruj kolejny plik',
        KRYPTLI_PAGE_TEXT: 'Chcesz również wysyłać bezpiecznie dokumenty? ',
        KRYPTLI_PAGE_LINK: 'Przejdź na naszą stronę'
    },
    LOGIN_PAGE: {
        LOG_IN: 'Zaloguj się',
        SIGN_IN: 'Zaloguj się',
        SIGN_IN_WITH_GOOGLE: 'Zaloguj się przez Google',
        FILE_DECRYPTION_PAGE_TEXT: 'Chcesz otworzyć pliki zaszyfrowane za pomocą Kryptli? ',
        FILE_DECRYPTION_PAGE_LINK: 'Przejdź tutaj',
        KRYPTLI_PAGE_TEXT: 'Wysyłaj bezpiecznie dokumenty do swoich kontaktów. Sprawdź ',
        KRYPTLI_PAGE_LINK: 'jak to działa',
        USER_NAME: 'Nazwa użytkownika',
        PASSWORD: 'Hasło',
        USER_NOT_CONFIRMED:
            'Użytkownik nie został potwierdzony. Sprawdź swoją skrzynkę pocztową i potwierdź rejestrację.',
        USER_NOT_FOUND_EXCEPTION: 'Podany użytkownik nie istnieje. Sprawdź dane logowania i spróbuj ponownie.',
        LOGIN_FAILED:
            'Przepraszamy, nie udało się zalogować. Upewnij się, że nazwa użytkownika i hasło są poprawne i spróbuj ponownie lub skontaktuj się z administratorem strony.',
        NOT_AUTHORIZED_EXCEPTION: 'Błąd autoryzacji. Niepoprawna nazwa użytkownika lub hasło.',
        PASSWORD_RESET_REQUIRED_EXCEPTION:
            'Twoje obecne hasło jest nieaktualne. Prosimy o zmianę hasła, aby uzyskać dostęp do aplikacji.',
        REGISTER: 'Nie masz konta? Zarejestruj się.',
        FORGOT_PASSWORD: 'Nie pamiętasz hasła?'
    },
    REGISTER: {
        EMAIL: 'E-mail',
        PASSWORD: 'Hasło',
        REPEAT_PASSWORD: 'Powtórz Hasło',
        REGISTER: 'Zarejestruj się',
        ACCEPTANCE_OF_TERMS_1: 'Rejestrując się akceptujesz naszą ',
        PRIVACY_POLICY: 'Politykę prywatności',
        BACK_TO_LOGIN_PAGE: 'Masz już konto? Zaloguj się',
        PASSWORDS_ARE_NOT_IDENTICAL: 'Podane hasła nie są identyczne.',
        USER_ALREADY_EXISTS:
            'Ten adres e-mail jest już w użyciu. Proszę podać inny adres e-mail lub zalogować się do istniejącego konta.',
        ACCOUNT_CREATION_FAILED:
            'Nie udało się utworzyć konta. Proszę spróbować ponownie później lub skontaktować się z administratorem strony.',
        CONFIRM_REGISTRATION: 'Potwierdź rejestrację',
        REGISTER_CONFIRMATION_MESSAGE1: 'Na podany e-mail: ',
        REGISTER_CONFIRMATION_MESSAGE2: ' wysłaliśmy Ci link aktywacyjny.',
        SEND_AGAIN: 'Wyślij ponownie',
        CODE_RESENT_SUCCESSFULLY: 'Wiadomość z linkiem aktywacyjnym została wysłana. Sprawdź swoją skrzynkę pocztową.',
        FAILED_RESEND_MESSAGE:
            'Nie udało się wysłać wiadomości. Spróbuj ponownie później lub skontaktuj się z administratorem strony.',
        CALL_TO_ACTION: 'Masz pytania lub chcesz umówić spotkanie z konsultantem? ',
        CONTACT_LINK: 'Skontaktuj się z nami'
    },
    REGISTER_CONFIRMATION: {
        CONFIRM_REGISTRATION: 'Potwierdź rejestracje',
        SUCCESS_MESSAGE: 'Twoje konto zostało pomyślnie utworzone i jest już gotowe do użycia.',
        SUCCESS_MESSAGE_TEXT:
            'Teraz możesz zalogować się do aplikacji za pomocą swojego adresu e-mail i hasła, które podałeś podczas rejestracji.',
        ACCOUNT_VERIFICATION_FAILED: 'Weryfikacja konta nie powiodła się',
        ACCOUNT_VERIFICATION_FAILED_TEXT:
            'Przepraszamy, ale nie udało się zweryfikować Twojego konta. Skontaktuj się z naszym zespołem wsparcia, abyśmy mogli pomóc Ci rozwiązać ten problem.',
        USER_NOT_FOUND_EXCEPTION:
            'Nie znaleziono użytkownika o podanej nazwie. Prosimy sprawdzić wprowadzone dane i spróbować ponownie.',
        NOT_AUTHORIZED_EXCEPTION:
            'Błąd potwierdzenia użytkownika. Użytkownik został już potwierdzony. Konto jest już aktywne i gotowe do użycia.',
        LIMIT_EXCEEDED_EXCEPTION:
            'Przekroczono limit prób weryfikacji konta. Prosimy spróbować ponownie za kilka minut.'
    },
    FORGOT_PASSWORD_PAGE: {
        FORGOT_PASSWORD: 'Niepamiętasz hasła?',
        TO_RESET_PASSWORD: 'Aby zrestartować hasło, wpisz adres e-mail powiązany ze swoim kontem.',
        EMAIL: 'E-mail',
        RESET_PASSWORD: 'Restartuj hasło',
        HEADER_MESSAGE: 'Potwierdź zmianę hasła',
        CHECK_EMAIL: 'Sprawdź swoją pocztę',
        MESSAGE1: 'Na podany e-mail: ',
        MESSAGE2: ' wysłaliśmy Ci link dezaktywujący hasło do Twojego konta.',
        EMAIL_NOT_RECEIVED:
            'Jeśli nie otrzymałeś/aś wiadomości, kliknij przycisk "Wyślij ponownie", lub skontaktuj się z administratorem strony.',
        RESEND_MESSAGE: 'Wyślij wiadomość ponownie.',
        ERROR_SEND_CODE_MESSAGE:
            'Nie udało się wysłać kodu resetującego hasło. Spróbuj ponownie lub skontaktuj się z administratorem strony.',
        ENTER_NEW_PASSWORD: 'Wpisz nowe hasło',
        SET_NEW_PASSWORD: 'Ustaw nowe hasło',
        PASSWORD_CHANGED: 'Twoje hasło zostało zmienione. Zaloguj się na swoje konto za pomocą nowego hasła.',
        PASSWORD_RESET_FAILED:
            'Przepraszamy, nie udało się zresetować hasła. Prosimy spróbuj ponownie lub skontaktuj się z administratorem strony.',
        EMAIL_SENT: 'E-mail z linkiem dezaktywującym hasło został wysłany.'
    },
    PASSWORD_VALIDATION: {
        PASSWORD_REQUIREMENTS: 'Hasło musi zawierać:',
        MIN_LENGTH: 'Minimum 8 znaków.',
        CONTAINS_LOWERCASE: 'Co najmniej jedną małą literę.',
        CONTAINS_UPPERCASE: 'Co najmniej jedną dużą literę.',
        CONTAINS_NUMBER: 'Co najmniej jedną cyfrę.',
        CONTAINS_SPECIAL_CHARACTER: 'Co najmniej jeden znak specjalny.'
    },
    RESET_PASSWORD_PAGE: {
        ERROR_WHILE_RESETTING_PASSWORD: 'Podczas resetowania hasła w aplikacji wystąpił błąd',
        ERROR_WHILE_RESETTING_PASSWORD_TEXT1:
            'Z przykrością informujemy, że podczas próby zresetowania i ustawienia nowego hasła w aplikacji wystąpił błąd.',
        ERROR_WHILE_RESETTING_PASSWORD_TEXT2:
            'Prosimy o ponowienie próby resetowania hasła lub skontaktowanie się z administratorem strony.',
        TRY_AGAIN: 'Spróbuj ponownie',
        EXPIRED_CODE_EXCEPTION:
            'Przepraszamy podany link do zmiany hasła jest nieprawidłowy lub wygasł. Prosimy o ponowną próbę resetowania hasła.'
    },
    NOT_FOUND_PAGE: {
        ERROR_404: 'Błąd 404',
        PAGE_NOT_FOUND: 'Strona nie została odnaleziona'
    },
    MODAL_NEW_CONTACT: {
        HEADER: 'Dodawanie nowego kontaktu',
        ADD_CONTACT: 'Dodaj kontakt',
        ADD_CONTACT_AND_SEND_PASSWORD: 'Dodaj kontakt i prześlij wygenerowane hasło SMS-em',
        IS_EMAIL: 'Podaj poprawny adres email.',
        IS_VALID_AREA_CODE: 'Podaj poprawny numer telefonu.',
        INCORRECT_EMAIL_ADDRESS: 'Niepoprawny adres email.',
        PHONE_NUMBER_ALREADY_EXISTS: 'Kontakt z podanym numerem telefonu już istnieje.',
        INVALID_PHONE_NUMBER: 'Niepoprawny numer telefonu.'
    },
    MODAL_UPLOAD_FILE: {
        HEADER: 'Zaszyfruj plik',
        ENCRYPTION_METHOD: 'Metoda szyfrowania',
        MAXIMUM_SIZE_MESSAGE: 'Maksymalny rozmiar 25MB',
        CHOOSE_FILE: 'Wybierz plik ',
        OR_DRAG_HERE: 'lub przeciągnij tutaj',
        FILE_NAME: 'Nazwa pliku',
        CHECKBOX_FILE_NAME: 'Zmień nazwę pliku',
        NEW_FILE_NAME: 'Nowa nazwa pliku',
        ENCRYPT_FILE: 'Zaszyfruj plik',
        SUCCESS_MESSAGE_HEADER: 'Twój plik został zaszyfrowany.',
        SUCCESS_MESSAGE_TEXT: 'Plik powinien zostać pobrany automatycznie, w innym wypadku',
        SUCCESS_MESSAGE_LINK: 'pobierz zaszyfrowany plik',
        IS_REQUIRED: 'To pole jest wymagane. Ustaw nową nazwę pliku.',
        PATTERN: 'Nazwa pliku nie może zawierać: \\  / : * ? " < > |',
        DOCUMENT_: 'Dokument_',
        PASSWORD_INFORMATION_MESSAGE: 'Hasło do odszyfrowania pliku nie zostało jeszcze wysłane. ',
        SEND_PASSWORD_ACTION: 'Kliknij tutaj',
        SEND_PASSWORD_MESSAGE: ', aby je wysłać i umożliwić odbiorcy odszyfrowanie pliku.'
    },
    MODAL_FILE_ENCRYPT: {
        DECRYPT_FILE_NOTIFICATION1: 'Odszyfrowanie pliku będzie możliwe tylko za pomocą aplikacji Kryptli. ',
        DECRYPT_FILE_NOTIFICATION_LINK: 'Skopiuj link ',
        DECRYPT_FILE_NOTIFICATION2: 'do strony, na której odbiorca będzie mógł odszyfrować plik.',
        DECRYPT_FILE_NOTIFICATION3:
            'do strony, na której odbiorca będzie mógł odszyfrować plik. Przekaż tę informację swojemu Klientowi.',
        TEXT_COPIED_NOTIFICATION: 'Link do strony został skopiowany do schowka.',
        COPY_FAILED_NOTIFICATION: 'Nie udało się skopiować linku, spróbuj ponownie.',
        CALL_TO_REMEMBER: 'Pamiętaj!'
    },
    MODAL_DEACTIVATION: {
        HEADER: 'Dezaktywować kontakt?',
        MESSAGE: 'Pamiętaj, że tej akcji nie można cofnąć.',
        DEACTIVATE: 'Usuń',
        cancel: 'Anuluj'
    },
    MODAL_EDIT_CONTACT: {
        HEADER: 'Edytuj kontakt',
        SAVE_CHANGES: 'Zapisz dane kontaktu'
    },
    MODAL_FILE_DECRYPTION: {
        HEADER: 'Odszyfrowywanie pliku',
        UPLOAD_ENCRYPTED_FILE: 'Wrzuć zaszyfrowany plik',
        CHOOSE_FILE: 'Wybierz plik ',
        OR_DRAG_HERE: 'lub przeciągnij tutaj',
        FILE_NAME: 'Nazwa pliku',
        CHECKBOX_FILE_NAME: 'Przywróć oryginalną nazwę pliku',
        DECRYPT_AND_DOWNLOAD_FILE: 'Odszyfruj i pobierz plik',
        SUCCESS_MESSAGE_HEADER: 'Twój plik został odszyfrowany.',
        SUCCESS_MESSAGE_TEXT: 'Plik powinien zostać pobrany automatycznie, w innym wypadku',
        SUCCESS_MESSAGE_LINK: 'pobierz zaszyfrowany plik',
        RECIPIENT: 'Odbiorca',
        ENCRYPTOR: 'Osoba szyfrująca',
        ENCRYPTION_DATE: 'Data szyfrowania',
        ENCRYPTION_METHOD: 'Metoda szyfrowania',
        DECRYPT_NEXT_FILE: 'Odszyfruj następny plik'
    },
    MODAL_PASSWORD: {
        PASSWORD: 'Hasło',
        PASSWORD_GENERATION_DATE: 'Data wygenerowania hasła',
        PASSWORD_SUBMISSION_DATE: 'Data przesłania hasła',
        SEND_CURRENT_PASSWORD: 'Wyślij aktualne hasło SMS-em',
        GENERATE_AND_SEND_NEW_PASSWORD: 'Wygeneruj i wyślij nowe hasło SMS-em',
        SUCCESS_MESSAGE_HEADER: 'Twój kontakt otrzyma następującą wiadomość sms:'
    },
    MODAL_TUTORIAL: {
        HEADER: 'Jak to działa?',
        TEXT_STEP_1:
            ' Na początku stwórz listę kontaktów, w której zdefiniujesz adres e-mail, numer komórkowy oraz opcjonalnie opis kontaktu.',
        TEXT_STEP_2:
            'Aplikacja generuje hasło do odszyfrowania plików i przesyła je drogą SMS tylko na wskazany numer kontaktu.',
        TEXT_STEP_3: 'Zaszyfruj w aplikacji plik, następnie prześlij go wybranemu kontaktowi na podany adres e-mail.',
        DOCUMENTATION_TEXT1: 'Zachęcamy do zapoznania się z naszą ',
        DOCUMENTATION_LINK: 'dokumentacją',
        DOCUMENTATION_TEXT2: ', gdzie znajdują się informacje dotyczące wszystkich funkcji aplikacji.',
        TUTORIAL_INFO_TEXT: 'Zawsze możesz tu wrócić klikając “Jak to działa?” w menu!',
        I_UNDERSTAND: 'Rozumiem'
    },
    MODAL_SMS_MESSAGE: {
        HEADER: 'Twój kontakt otrzyma poniższą wiadomość SMS',
        COMPANY_NAME: '#Nazwa Twojej firmy',
        NEW_CONTACT_MESSAGE:
            ' dodała Cię do listy kontaktów w aplikacji Kryptli oraz wygenerowała dla Ciebie hasło do otwierania dokumentów. Twoje hasło to: ',
        GENERATE_PASSWORD_MESSAGE: ' wygenerowała dla Ciebie nowe hasło. Twoje hasło dostępu do dokumentów to: ',
        SEND_PASSWORD_MESSAGE: ' wysyła Ci przypomnienie aktualnego hasła. Twoje hasło dostępu do dokumentów to: ',
        GENERATE_PIN_CODE_MESSAGE: 'Twój jednorazowy kod do odszyfrowania pliku to: '
    },
    ENCRYPTION_METHODS: {
        TOOLTIP_AES256:
            'Format szyfrowania AES-256 jest zalecanym standardem szyfrowania jeśli chodzi o zabezpieczenie plików\n' +
            '                hasłem przed niechcianymi użytkownikami. AES-256 nie jest niestety obsługiwany przez domyślne narzędzia\n' +
            '                systemowe do obsługi plików Zip. Wymagane jest dodatkowe oprogramowanie np. ',
        OR_OTHER: 'lub inne obsługujące dany standard.',
        INFORMATION_FOR_CONTACT: 'Informacja dla kontaktu:',
        COPY_TEXT: 'SKOPIUJ TEKST',
        TEXT_COPIED_NOTIFICATION: 'Tekst został skopiowany do schowka.',
        CLIPBOARD_TEXT:
            'Do otwarcia zaszyfrowanego pliku wymagane jest dodatkowe oprogramowanie np. 7-zip, WinRAR, WinZIP lub inne obsługujące standard szyfrowania AES.',
        TOOLTIP_ZIP:
            ' Format szyfrowania Zip 2.0 (ZipCrypto) jest obsługiwany przez prawie wszystkie narzędzia do obsługi\n' +
            '                plików Zip - włączajac w to domyślne narzędzie w systemie Windows, a więc nie wymaga instalacji\n' +
            '                dodatkowego oprogramowania. Ochrona hasłem pliku Zip za pomocą szyfrowania Zip 2.0 zapewnia ochronę\n' +
            '                przed przeciętnym użytkownikiem, który nie ma hasła i próbuje określić zawartość plików. Jednak format\n' +
            '                szyfrowania Zip 2.0 jest stosunkowo słaby i nie można oczekiwać, że zapewni ochronę przed osobami, które\n' +
            '                mają dostęp do specjalistycznych narzędzi do odzyskiwania haseł. Nie należy polegać na szyfrowaniu Zip\n' +
            '                2.0, aby zapewnić silne bezpieczeństwo danych.'
    },
    SEARCH: {
        REQUIREMENT: 'Wprowadź minimum 3 znaki',
        SEARCH_AFTER: 'Szukaj po użytkowniku lub informacjach dodatkowych'
    },
    ALT: {
        EDIT_ICON: 'Ikona edytuj',
        PASSWORD_ICON: 'Ikona hasła',
        HISTORY_ICON: 'Ikona historii',
        DEACTIVATION_ICON: 'Ikona dezaktywacji',
        MORE_ACTIONS_ICON: 'Ikona więcej akcji',
        ACTIVE_CONTACT_ICON: 'Ikona aktywnego kontaktu',
        DEACTIVATED_CONTACT_ICON: 'Ikona zdezaktywowanego kontaktu.',
        MORE_INFORMATION_ICON: 'Ikona więcej informacji',
        SUCCESS_ICON: 'Ikona sukces',
        INFORMATION_ICON: 'Ikona informacji',
        KRYPTLI_APP_LOGO: 'Logo aplikacji Kryptli',
        USER_ICON: 'Ikona użytkownika',
        APP_LOGOUT_ICON: 'Ikona wylogowania z aplikacji',
        EMPTY_CONTACT_LIST_ICON: 'Ikona pustej listy kontaktów',
        ENCRYPTION_AND_SECURE_DOCUMENT_TRANSFER: 'Szyfrowanie i bezpieczne przesyłanie dokumentów',
        GOOGLE_ICON: 'Ikona Google'
    },
    DEMO_NOTIFICATION: {
        HEADER: 'Wersja demonstracyjna',
        HEADER2: 'Korzystasz z wersji demo',
        TEXT: 'Możesz wypróbować wszystkie funkcjonalności systemu, ale żadne wiadomości nie zostaną wysłane do Twoich kontaktów. Jesteś zainteresowany pełną wersją aplikacji? ',
        TEXT2: 'Chcesz odblokować wszystkie funkcje? ',
        CONTACT_LINK: 'Zamów pełną licencję'
    }
};
